export const MAINTENANCE_MODE = 'maintenance_mode';
export const SHOW_NO_FIXED_PLANS = 'no_fixed_plans_available';
export const OSE_SMART_BOOKING_OFFER_FLAG =
  'action_centre_manage_smart_meter_booking';
export const MI_BANNER = 'major_incident_banner';
export const SMART_THERMOSTATS_MAINTENANCE = 'smart_thermostat_maintenance';
export const MY_EV_PAGE_ALPHA = 'my_ev_page';
export const RENEWAL_LINK_TO_OFFERS =
  'renewal_show_cta_link_to_offers_experiment';
export const DRIVE_ANYTIME = 'drive_anytime';
export const RETENTION_BANNER_WARNING = 'renewal_issues_warning_banner';
export const RETENTION_BANNER_PRICE_CAP = 'renewal_price_cap_banner';
export const SHOW_DETAILED_CURRENT_PLAN =
  'renewal_show_detailed_current_plan_experiment';
export const PACE_PLANNED_MAINTENANCE = 'pace_planned_maintenance';
export const HIDE_ACTION_CENTRE = 'hide_action_centre';
export const ONE_CLICK_PAYMENT_REDUCTION = 'show_direct_debit_reduction';
export const HIDE_FLAKY_NAV = 'hide_flaky_nav';
export const HIGH_TRAFFIC_INTERCEPT_PAGE = 'high_traffic_intercept_page';
export const SHOW_RECOMMENDED_DIRECT_DEBIT = 'show_recommended_direct_debit';
export const SHOW_CARD_PAYMENT_POL = 'use_pol_as_payments_provider';
export const ALLOW_ANYTIME_SIGNUP = 'allow_anytime_signup';
export const APP_DOWNLOAD_VIA_ANYTIME_JOURNEY =
  'app_download_via_anytime_journey';
export const OVO_FOUNDATION_CARD_UPDATED_DESIGN =
  'ovo_foundation_card_updated_design';
export const COLLECTABLE_BALANCE_ROLLOUT = 'collectable_balance_rollout';
export const TARIFFS_FILTERING = 'tariffs_filtering';
export const ENABLE_MOVE_IN_JOURNEY = 'new_move_in_journey_experiment';
export const MOVE_IN_TARIFFS_FILTERING = 'move_in_tariffs_filtering';
export const SHOW_GREENER_ENERGY = 'show_greener_energy';
export const SHOW_GREENER_ELECTRICITY = 'show_greener_electricity';
export const BILLING_HISTORY_CALLOUT = 'billing_history_callout';
export const EXPAND_FIRST_BILLING_PERIOD = 'expand_first_billing_period';
export const MOVE_OUT_CTA = 'move_out_cta';
export const HOMEPLAN_DISCOUNT_PROMO = 'homeplan_discount_promo';
export const HOMEPLAN_OFFER = 'home_plan_offer';
export const HOMEPLAN_BLACK_FRIDAY_OFFER = 'black_friday';
export const HOMEPLAN_NAV_LINK = 'homeplan_nav_link';
export const HOMERECOVER_NAV_LINK = 'homerecover_nav_link';
export const HOME_RECOVER_OFFER = 'homerecover_offer';
export const SHOW_ABS_IN_MORE_OFFERS = 'show_abs_in_more_offers';
export const OMR_WITHOUT_MSN = 'omr_without_msn';
export const SHOW_POWER_MOVE = 'show_power_move';
export const METER_SUMMARY = 'meter_summary';
export const SHOW_DD_SVT_BANNER = 'show_dd_banner_for_svt_customers';
export const SHOW_PATH_TO_ZERO_OFFERS = 'show_path_to_zero_offers';
export const IS_KAPI_SUPPLY_POINTS_ENABLED = 'is_kapi_supply_points_enabled';
export const USE_KAPI_BOOTSTRAP = 'use_kapi_bootstrap';
export const PROMO_FREE_FOR_FIRST_FOUR_MONTHS_ENABLED =
  'promo_free_for_first_four_months_enabled';
export const BLACK_FRIDAY = 'black_friday';
export const ENABLE_MHHS_READ_FREQUENCY_SETTINGS =
  'enable_mhhs_read_frequency_settings';
export const HOMEPLAN_Q1_PROMO_OFFER = 'home_plan_q1_offer';
export const HOMEPLAN_FIXED_Q1_PROMO_OFFER = 'home_plan_fixed_q1_offer';
export const ENERGY_CREDITS_PROMO = 'energy_credits_promo';
export const ENERGY_CREDITS_PROMO_FIXED = 'energy_credits_promo_fixed';
export const SHOW_RENEWALS = 'show_orion_renewal';
export const HYPERVOLT_CHARGE_ANYTIME =
  'hypervolt_charger_eligible_for_charge_anytime';
export const ANDERSEN_CHARGE_ANYTIME =
  'andersen_charger_eligible_for_charge_anytime';
export const PAY_AS_YOU_DRIVE = 'pay_as_you_drive';
export const PAY_AS_YOU_DRIVE_VOLVO = 'pay_as_you_drive_volvo';
export const PAY_AS_YOU_DRIVE_VOLVO_OCT = 'pay_as_you_drive_volvo_oct';
export const PAY_AS_YOU_DRIVE_VWG = 'pay_as_you_drive_vwg';
export const VWG_ORDER_NUMBER_FIELD = 'vwg_order_number_field';
export const HOMEPLAN_ABS_Q2_OFFER = 'homeplanabsq2offer';
export const HOMEPLAN_Q42024_OFFER = 'homeplanq42024offer';
export const HOMEPLAN_Q42024_FLASH_SALE_OFFER = 'homeplanq42024flashsaleoffer';
export const HOMEPLAN_Q42024_BLACK_FRIDAY_OFFER =
  'homeplanq42024blackfridayoffer';
export const REMOVE_ENERGY_CREDITS_1_YEAR_FIXED =
  'remove_energy_credits_1_year_fixed';
export const ENABLE_PARTNERS = 'enable_partners';
export const BALANCE_COMPONENT_CHANGES = 'balance_component_changes';
export const BILLING_COMPREHENSION = 'billing_comprehension';
export const CHARGE_ANYTIME_API = 'charge_anytime_api';

export const INDRA_CHARGE_ANYTIME_APP_ENABLED =
  'indra_charge_anytime_app_enabled';
