import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SmartChargers } from './anytime-chargers';
import {
  eligibilityResponse,
  IneligibleReasons,
  useEligibilityCheck,
} from './use-eligibility-check';
import { EligibilityForPayAsYouDrive, EligibilityQuestions } from './types';
import { eligibleForPromo, PromoType } from './anytime-vehicles';

interface FeatureFlags {
  isPayAsYouDriveEnabled: boolean;
  isPayAsYouDriveVolvoEnabled: boolean;
  isPayAsYouDriveVolvoOctEnabled: boolean;
}

interface SmartMeterFlags {
  hasSmartMeter: boolean;
}

interface ChargeAnytimeProps extends FeatureFlags, SmartMeterFlags {
  useCheckEligibilityForPayAsYouDrive(): EligibilityForPayAsYouDrive;
}

export interface ChargeAnytimeContextValue
  extends EligibilityQuestions,
    FeatureFlags,
    SmartMeterFlags {
  setSmartCharger(smartCharger: SmartChargers): void;
  setMake(make: string | null): void;
  setModel(model: string | null): void;
  setYear(year: number | null): void;
  setHasInternetAccess(hasInternetAccess: boolean | null): void;
  setHasSolarPanels(hasSolarPanels: boolean | null): void;
  eligibility: eligibilityResponse & Partial<IneligibleReasons>;
  isError: boolean;
  isFetching: boolean;
  ineligibleNotSmartMeter: boolean;
  ineligibleInvalidMeterReadFrequency: boolean;
  ineligibleOnlyGas: boolean;
  ineligibleOther: boolean;
  isEligibleVolvo: boolean | PromoType;
}

export const ChargeAnytimeContext =
  createContext<ChargeAnytimeContextValue | null>(null);

export const useChargeAnytimeContext = (): ChargeAnytimeContextValue => {
  const contextValue = useContext(ChargeAnytimeContext);
  if (contextValue === null) {
    throw new Error('Must be used inside ChargeAnytime Context');
  }
  return contextValue;
};

export const ChargeAnytimeProvider = ({
  children,
  useCheckEligibilityForPayAsYouDrive,
  hasSmartMeter,
  isPayAsYouDriveVolvoEnabled,
  isPayAsYouDriveVolvoOctEnabled,
  isPayAsYouDriveEnabled,
}: PropsWithChildren<ChargeAnytimeProps>) => {
  const [smartCharger, setSmartCharger] = useState<SmartChargers>(
    SmartChargers.Indra
  );
  const [make, setMake] = useState<string | null>(null);
  const [model, setModel] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(null);
  const [hasInternetAccess, setHasInternetAccess] = useState<boolean | null>(
    null
  );
  const [hasSolarPanels, setHasSolarPanels] = useState<boolean | null>(null);

  const {
    ineligibleNotSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOnlyGas,
    isFetching,
    isError,
    ineligibleOther,
  } = useCheckEligibilityForPayAsYouDrive();

  const eligibility = useEligibilityCheck({
    make,
    model,
    year,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
    ineligibleNotSmartMeter,
    ineligibleOnlyGas,
    hasSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOther,
    isPayAsYouDriveEnabled,
    isPayAsYouDriveVolvoEnabled,
    isPayAsYouDriveVolvoOctEnabled,
  });

  const isEligibleVolvo = useMemo(() => {
    return (
      isPayAsYouDriveVolvoEnabled &&
      make !== null &&
      eligibleForPromo(
        make,
        model,
        year,
        smartCharger,
        !!hasSolarPanels,
        !!hasInternetAccess,
        isPayAsYouDriveVolvoOctEnabled
      )
    );
  }, [
    isPayAsYouDriveVolvoEnabled,
    make,
    model,
    year,
    smartCharger,
    hasSolarPanels,
    hasInternetAccess,
  ]);

  return (
    <ChargeAnytimeContext.Provider
      value={{
        smartCharger,
        setSmartCharger,
        make,
        setMake,
        model,
        setModel,
        year,
        setYear,
        hasInternetAccess,
        setHasInternetAccess,
        hasSolarPanels,
        setHasSolarPanels,
        eligibility,
        isError,
        isFetching: !!isFetching,
        isPayAsYouDriveEnabled,
        isPayAsYouDriveVolvoEnabled,
        isPayAsYouDriveVolvoOctEnabled,
        hasSmartMeter,
        ineligibleNotSmartMeter,
        ineligibleInvalidMeterReadFrequency,
        ineligibleOnlyGas,
        ineligibleOther,
        isEligibleVolvo,
      }}
    >
      {children}
    </ChargeAnytimeContext.Provider>
  );
};
