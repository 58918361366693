import { SmartChargers } from './anytime-chargers';
import {
  isVehicleInList,
  volvoPartnershipVehicles,
  volvoPartnershipVehiclesOct,
} from './anytime-vehicles';

export type Criteria = {
  charger: SmartChargers;
  vehicle: {
    make: string | null;
    model: string | null;
    year: number | null;
  };
  hasSolar: boolean;
  hasVehicleInternet: boolean;
  featureFlags: {
    payAsYouDriveVolvo: boolean;
    payAsYouDriveVolvoOct: boolean;
    isIndraChargeAnytimeApp: boolean;
  };
};

export type IntegrationType = 'Indra' | 'Ohme' | 'KaluzaCaApp' | 'Volvo';

/**
 * This function presumes eligibility!
 * Passing it ineligible criteria will result in incorrect results
 */
export const getIntegrationType = (criteria: Criteria): IntegrationType => {
  if (
    criteria.featureFlags.payAsYouDriveVolvo &&
    isVehicleInList(
      criteria.featureFlags.payAsYouDriveVolvoOct
        ? volvoPartnershipVehiclesOct
        : volvoPartnershipVehicles
    )(criteria.vehicle.make, criteria.vehicle.model, criteria.vehicle.year) &&
    !criteria.hasSolar &&
    criteria.hasVehicleInternet
  ) {
    return 'Volvo';
  }

  if (
    !criteria.featureFlags.isIndraChargeAnytimeApp &&
    criteria.charger === SmartChargers.Indra
  ) {
    return 'Indra';
  }

  if (criteria.charger === SmartChargers.Ohme) {
    return 'Ohme';
  }

  return 'KaluzaCaApp';
};
